.tech-stack-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 50px 20px;
}

.embla {
    overflow: hidden;
}

.embla__container {
    display: flex;
    width: 100%; 
    padding: 40px 0;
}

.embla__slide {
    flex: 0 0 calc(100% / 3); 
    min-width: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin: 0 10px;
}

.carousel-scroll-btn-container {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.carousel-scroll-btn {
    background-color: var(--white);
    border: none;
    font-size: 1rem;
    color: var(--black);
    border-bottom: 1px solid var(--black);
    transition: transform 0.3s ease-in-out, border-color 0.3s ease-in; 
}

.carousel-scroll-btn:hover {
    font-weight: bold;
    transform: scale(1.2);
    border-color: transparent; 
}


.carousel-scroll-btn:hover {
    font-weight: bold;
    transform: scale(1.2);
}

.embla__slide span {
    font-weight: 500;
}
  
.embla__slide img {
    width: 50px;
}

@media only screen and (min-width: 480px) {
    .tech-stack-container {
        padding: 100px 0;
        gap: 50px;
    }

    .embla__slide {
        flex: 0 0 calc(100% / 8); 
    }

    .embla__slide img {
        padding: 15px;
        width: 80px;
    }
} 



  