.project-card-container {
  display: grid;
  grid-template-columns: 1fr;
  padding: 20px 10px;
  background-color: var(--white); 
  line-height: 1.6rem;
  color: var(--black);
  row-gap: 30px;
}

.project-card-img-container {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 10px;
    position: relative;
    padding: 10px;
    background: linear-gradient(to right, var(--tertiary-color), var(--primary-color), var(--secondary-color));
}


.project-card-img-container img {
    display: block;
    height: 150px;
}

.project-title {
    font-size: 1.2rem;
    font-weight: 500;
    padding-bottom: 5px;
}

.project-card-description {
    line-height: 1.5rem;
    text-align: center;
}

.project-card-text-container {
    grid-area: text;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
}

.project-list-container {
    display: grid;
    grid-template-rows: auto;
    gap: 10px;
    padding-top: 10px;

}

.project-card-list {
    color: var(--mid-grey);
    line-height: 1.2rem;
    list-style: none;
}

.project-card-container-links {
    display: flex;
    gap: 10px;
    padding: 10px 0;
    color: var(--black);
}

.project-card-button {
    background-color: var(--white);
    font-size: 18px;
    text-align: left;
    color: var(--black);
    border: none;
    border-bottom: 1px solid var(--black);
    width: 85px;
    padding-top: 10px;
    font-weight: 500;
    transition: transform 0.3s ease-in-out, border-color 0.3s ease-in; 
}

.project-card-button:hover {
    transform: scale(1.1);
}

@media only screen and (min-width: 480px) {
    .project-card-container  {
        margin-left: 50px;
    }

    .project-card-img-container img {
        width: auto; 
        height: 200px;
    }

    .project-card-description {
        text-align: left;
        width: 95%;
    }

    .project-card-text-container {
        align-items: start;
    }

    .project-list-container {
        grid-template-columns: 1fr 1fr;
        padding: 20px 0;
    }

    .project-card-list {
        font-size: 0.8rem;
    }
}


