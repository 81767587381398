@import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Display:wght@700&family=Climate+Crisis&family=Fraunces:opsz,wght@9..144,700&family=Hanken+Grotesk:wght@500;700;800&family=Inter:wght@400;700&family=Lexend+Deca&family=Montserrat:wght@500;700&family=Poppins:wght@600&family=Rock+Salt&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Display:wght@700&family=Climate+Crisis&family=Fraunces:opsz,wght@9..144,700&family=Hanken+Grotesk:wght@500;700;800&family=Inter:wght@400;700&family=Lexend+Deca&family=Montserrat:wght@500;700&family=Poppins:wght@600&family=Quicksand:wght@300&family=Rock+Salt&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Display:wght@700&family=Climate+Crisis&family=Fraunces:opsz,wght@9..144,700&family=Gelasio:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Hanken+Grotesk:wght@500;700;800&family=Inter:wght@400;700&family=Lexend+Deca&family=Montserrat:wght@500;700&family=Poppins:wght@600&family=Quicksand:wght@300&family=Rock+Salt&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');

:root {
  --primary-color: #ffc0cb;
  --secondary-color:#f4dbf8;
  --tertiary-color: #f9e4c8;
  --highlight-color: #ee38a8;
  --grey: #eeeef3;
  --mid-grey: #646465;
  --black: #000000;
  --white: #ffffff;
  --black-opacity: rgba(0, 0, 0, 0.5); 
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  line-height: 1.5rem;
  font-family: 'Poppins';
  max-width: 1400px;
  height: 100%;
  overflow-x: hidden;
  margin: 0 auto;
}

.app {
  display: flex;
  flex-direction: column;
}

h2 {
  text-align: center;
}

.subtitle-span {
  display: inline-block;
  color: var(--highlight-color);
  animation: swingAndScale 2s infinite alternate; 
  transform-origin: center;
}

@keyframes swingAndScale {
  0%, 100% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(-45deg) scale(1.4);
  }
}



