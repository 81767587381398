.navbar {
  z-index: 1000;
  display: flex;
  flex-direction: column;
  width: 100vw;
  background-color: var(--white);
  position: absolute;
  top: 0px;
  padding-bottom: 10px;
}

.btn {
  display: flex;
  align-self: flex-end;
}

.menu-items {
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-size: 1.5em;
}

.header-menu-items {
  display: none;
}

.list-item {
  text-decoration: none;
  padding: 1em;
  width: 8em;
  color: var(--black);
}

#home {
  font-weight: bold;
}

.close-btn {
  font-size: 1.1em;
  padding: 21px 20px 0 0;
  border: none;
  background-color: var(--white);
  text-decoration: underline;
  color: var(--black);
}

.close-btn:hover {
  font-size: 2em;
  font-weight: bold;
}

.animate__animated.animate__slideInRight {
  --animate-duration: 0.8s;
}

.social-media-links {
  padding-top: 20px;
}

@media screen and (min-width: 480px) {
  .navbar {
    display: none;
  }
}
