.home-projects-container {
    padding: 40px 0;
    width: 90%;
    margin: 0 auto;
}

.home-projects-titles {
    display: flex;
    justify-content: center;
    gap: 20px;
    cursor: pointer;
}

.active {
    border-bottom: 2px solid var(--black);
    padding-bottom: 6px;
}

.project-cards {
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
}

@media only screen and (min-width: 480px) {
    .home-projects-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas: 
            "title title"
            "project project";
        gap: 20px;
        padding: 40px 30px;
        width: 80%;
    }
    
    .project-cards {
        grid-area: project;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
    }

    .home-projects-titles {
        grid-area: title;
    }
}