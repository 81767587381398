@import url('https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.home-hero-container {
  z-index: 1;
  display: grid;
  grid-template-columns: 1fr; 
  grid-template-rows: auto;
  align-items: center;
  row-gap: 10px;
  padding: 80px 0 20px 0;
  height: auto;
  width: 90%;
  margin: 0 auto;
  background-color: var(--white);
}

.home-hero-text-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 20px;
  padding: 30px 0;
}

h1 {
  font-weight: 500;
  font-size: 36px;
  line-height: 2.5rem;
}

.h1-span {
  display: inline-block;
}

.animate__animated.animate__bounceInRight {
  --animate-duration: 1.5s;
}


.home-hero-img-container {
  background-image: url('https://cdn.pixabay.com/photo/2018/02/15/10/52/watercolor-3154948_1280.png');
  background-size: contain;
  background-repeat: no-repeat;
}

.home-hero-img {
  display: block;
  width: 100%;
}

.cta {
  border: none;
  font-weight: 550;
  font-size: 1.1rem;
  text-align: left;
  font-size: 20px;
  width: 110px;
  background-color: transparent;
  color: var(--black);
  border-bottom: 1px solid var(--black);
}

@media only screen and (min-width: 480px) {
  .home-hero-container {
    grid-template-columns: .5fr 4.5fr 6.5fr .5fr;
    grid-template-areas: 
    ". img h1 ."
    ". img text ."
    ". btn text .";
    column-gap: 50px;
    row-gap: 20px;
    padding: 50px 0; 
    width: 100%;
    margin: 0;
  }

  h1 {
    grid-area: h1;
    font-weight: 600;
    font-size: 72px;
    line-height: 5rem;
    padding-top: 50px;
    width: 90%;
  }

  .home-hero-text-wrapper {
    grid-area: text;
    grid-template-columns: 1fr 1fr;
    font-weight: 500;
    font-size: 18px;
    gap: 30px;
  }

  .home-hero-img-container {
    grid-area: img;
    margin: 0 auto;
    height: 100%;
  }

  .home-hero-img {
    display: block;
    height: 480px;
    width: 100%;
  }

  .cta {
    grid-area: btn;
    transition: transform 0.3s ease-in-out, border-color 0.3s ease-in; 
  }
}

.cta:hover {
  transform: scale(1.1);
}








