.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--black-opacity);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.project-popup {
    background-color: var(--white);
    padding: 20px;
    width: 90%;
    overflow-y: auto;
    position: relative; 
    margin-top: 40px;
    max-height: 80%;
}

.project-popup-inner {
    margin-top: 20px;
}

.project-popup-close-btn {
    color: var(--black);
    background-color: var(--white);
    position: absolute;
    font-size: 1.1rem;
    top: 10px;
    right: 20px;
    border: none;
    text-decoration: none;
    border-bottom: 1px solid var(--black);
}

.project-popup-title {
    font-size: 1.2rem;
    font-weight: 500;
}

.project-popup-close-btn:hover {
    font-weight: bold;
}

.project-card-img-container img {
    max-width: 100%;
}

.project-card-link {
    color: var(--black);
    text-decoration: none;
    border-bottom: 1px solid var(--black);
}

.project-card-link:hover {
  font-weight: bold;
}

.project-popup-list-container {
    display: grid;
    grid-template-rows: auto;
    gap: 10px;
    padding: 15px 0;
}

@media only screen and (min-width: 480px) {
    .project-popup-list-container {
        grid-template-columns: 1fr 1fr;
        padding: 20px 0;
    }

    .project-popup {
        width: 40%;
        margin-top: 40px;
    }
}
