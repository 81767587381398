footer {
  background-color: var(--grey);
  padding: 20px;
  font-size: .875rem;
  display: grid;
  grid-template-areas: 
  "profiles profiles" 
  "email email"
  "attribution attribution";
  grid-template-columns: 5fr 7fr;
  grid-template-rows: auto;
  column-gap: 10px;
  row-gap: 20px;
  align-items: center;
  margin-top: 70px;
}

.footer-subsection {
  display: flex;
  flex-direction: column;
}

.footer-email-wrapper {
  grid-area: email;
}

.footer-profiles-wrapper {
  grid-area: profiles;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-copyright-wrapper {
  grid-area: attribution;
}

.footer-text-title {
  font-weight: bold;
  font-size: .875rem;
}

.footer-linkedIn-icon {
  width: 16px;
  margin-right: 10px;
}

.footer-github-icon {
  width: 16px;
}

@media only screen and (min-width: 480px) {
  footer {
    padding-left: 6rem;
    grid-template-areas: 
  "attribution . email profiles";
  grid-template-columns: 3fr 3fr 2fr 2fr;
  column-gap: 10px;
  }

  .footer-subsection {
    align-items: flex-start;
    justify-content: center;
  }

  .footer-linkedIn-icon {
    margin-right: 10px;
  }
}