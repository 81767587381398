.skill-cards-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    align-content: center; 
    justify-items: center;
    padding: 40px 40px 0 40px; 
}

.skill-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    width: 300px;
    padding: 20px 0;
    text-align: center;
}

.skill-card img {
    width: 80px;
    padding-bottom: 5px;
}

.skill-card:hover {
    transform: translateY(-10px) translateZ(50px);
}

#skills {
    padding-top: 30px;
}

.skills-title {
    text-align: center;
    font-size: 1.9rem;
    padding-top: 40px;
}

.skill-name {
    color: var(--black);
    background: linear-gradient(to right, var(--secondary-color), var(--primary-color), var(--tertiary-color));
    font-size: 1.2rem;
    margin-bottom: 5px;
    margin: 0 50px;
    padding: 5px 10px;
}

.skill-description {
    font-size: 0.9rem;
}

@media only screen and (min-width: 480px) {
    .skill-cards-container {
        grid-template-columns: repeat(2, 1fr);
        padding: 20px; 
    }
}

@media only screen and (min-width: 768px) {
    .skill-cards-container {
        grid-template-columns: repeat(3, 1fr);
        padding: 60px 200px 0 200px; 
    }
}
