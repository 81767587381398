.home-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }

  h1 {
    font-size: 20px;
  }

  h2 {
    font-size: 1.9rem;
  }