.popup-overlay {
  position: fixed;
  top: 40px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--black-opacity); 
  z-index: 999; 
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  background-color: var(--white);
  color: var(--black);
  padding: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 1000; 
  width: 90%; 
  max-width: 600px; 
  margin: 100px auto 0 auto;
  position: relative;
}

.popup-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
}

.popup-inner h3 {
  line-height: 2rem;
  padding-top: 20px;
  font-size: 26px;
}

.popup-inner form  {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.form-input {
  border: none;
  border-bottom: 1px solid var(--black);
  border-radius: 0;
  padding: 10px;

}

.form-textarea {
  height: 150px;
}

.popup-close-btn {
  background-color: var(--white);
  color: var(--black);
  font-size: 18px;
  border: none;
  text-align: right;
  position: absolute; 
  top: 10px; 
  right: 10px; 
  width: 48px;
  border-bottom: 1px solid var(--black);
  transition: transform 0.3s ease-in-out, border-color 0.3s ease-in; 
}

.popup-close-btn:hover {
  font-weight: bold;
}

.popup-submit-btn {
  color: var(--black);
  background-color: var(--white);
  border: none;
  text-align: left;
  font-size: 18px;
  width: 58px;
  border-bottom: 1px solid var(--black);
  transition: transform 0.3s ease-in-out, border-color 0.3s ease-in; 
}

.popup-submit-btn:hover {
  font-weight: bold;
}

@media only screen and (min-width: 480px) {
  .popup {
    width: 80%;
  }
}
