@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ultra:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');

header {
    background-color: var(--white);
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-content: center;
    position: sticky;
    top: 0;
    z-index: 1000;
    padding: 20px;
}

.header-logo-name-text {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: var(--black);
    font-family: 'Poppins';
    font-size: 32px;
    font-weight: bolder;
    line-height: 2rem;
    background-color: var(--white);
}

.header-menu-container {
    justify-self: self-end;
}

.menu-btn {
    background-color: var(--white);
    color: var(--black);
    font-weight: 400;
    font-size: 18px;
    padding: 0;
    border: none
}

.nav-btn {
    display: none;
}

@media only screen and (min-width: 480px) {
    header {
        display: grid;
        grid-template-areas: 
        "logo . cv portfolio skills stack contact . links";
        grid-template-columns: 2fr 1fr .5fr .8fr .6fr 1fr 1fr 1fr 1fr;
        column-gap: 10px;
        justify-content: center;
        align-items: center;
        padding: 30px;
    }
  
    .header-logo-name-text {
      display: block;
      font-weight: 600;
      font-size: 1.5rem;
    }

    .header-logo-name-wrapper {
        grid-area: logo;
        font-size: 1rem;
    }

    .header-menu-container {
        display: none;
    }

    #cv {
        display: block;
        grid-area: cv;
    }

    #projects-wrapper {
        display: block;
        grid-area: portfolio;
    }
      
    #skills-wrapper {
        grid-area: skills;
        display: block;   
    }

    #tech-stack-wrapper {
        grid-area: stack;
        display: block;  
    }

    #contact {
        grid-area: contact;
    }

    #social-media-links {
        grid-area: links;
        display: flex;
        justify-content: center;
        gap: 15px;
        margin-top: -10px;
    }

    .social-media-icon {
        display: block;
        transition: transform 0.3s ease-in-out, border-color 0.3s ease-in; 
    }

    .social-media-icon:hover {
        transform: scale(1.2);
    }

    .nav-btn {
        display: block;
        text-decoration: none;
        color: var(--black);
        font-weight: 500;
        font-size: 18px;
        transition: transform 0.3s ease-in-out, border-color 0.3s ease-in; 
    }

    .nav-btn:hover {
        font-weight: bold;
        text-decoration: underline;
        transform: scale(1.1);
    }
}
